import React, { useEffect, useState } from "react"
import "../scss/DataConsultancyPicture1.scss"
import { graphql, useStaticQuery } from "gatsby"
import Aos from "aos";
import "aos/dist/aos.css";
import { StaticImage } from "gatsby-plugin-image"

export default function DataConsultancyPicture1() {
  const data = useStaticQuery(graphql`
    query MyQueryDRPic1 {
      allContentfulImageBlock(filter: { name: { eq: "dcpic1" } }) {
        edges {
          node {
            image {
              altText
              image {
                file {
                  url
                }
              }
            }
            name
          }
        }
      }
    }
  `);

  useEffect(() => {
    Aos.init({ duration: 1000, offset: -60 });
  }, []);

  if (data.allContentfulImageBlock.edges.length === 0) {
    return null; // Handle the case when the desired node is not found
  }

  return (
    <div className="contentFeatureDataConsultancyPic1">
      <div className={"imageContainerDataConsultancyPic1"}>
        <StaticImage data-aos="fade-up" className={"bigImage"} src="https://images.ctfassets.net/x5jqyuhgi4rx/5Qr6KKL8NEeguKGtvXVeP/349b5b58b481c1f9a0a259ebb24940aa/dcpic1_5_2x.png" layout="fullWidth" alt="Luxury VIP Customer"/>
      </div>
    </div>
  );
}
