import * as React from "react"

import Layout from "../components/Layout"
import HeroImageDataConsultancy from "../components/DataConsultancyPage/HeroImageDataConsultancy"
import DataConsultancyPageText1 from "../components/DataConsultancyPage/DataConsultancyPageText1"
import DataConsultancyPageText2 from "../components/DataConsultancyPage/DataConsultancyPageText2"
import DataConsultancyPageText3 from "../components/DataConsultancyPage/DataConsultancyPageText3"
import DataConsultancyPageText4 from "../components/DataConsultancyPage/DataConsultancyPageText4"
import DataConsultancyPageText5 from "../components/DataConsultancyPage/DataConsultancyPageText5"
import DataConsultancyPageText6 from "../components/DataConsultancyPage/DataConsultancyPageText6"
import DataConsultancyPicture1 from "../components/DataConsultancyPage/DataConsultancyPicture1"
import DataConsultancyPicture2 from "../components/DataConsultancyPage/DataConsultancyPicture2"
import DataConsultancyPicture3 from "../components/DataConsultancyPage/DataConsultancyPicture3"
import DataConsultancyPicture4 from "../components/DataConsultancyPage/DataConsultancyPicture4"
import DataConsultancyPicture5 from "../components/DataConsultancyPage/DataConsultancyPicture5"
// import SentimentPagePic1 from "../components/LuxurySentimentPage/SentimentPagePic1"
// import SentimentPageText2 from "../components/LuxurySentimentPage/SentimentPageText2"
// import SentimentPagePic2 from "../components/LuxurySentimentPage/SentimentPagePic2"
// import SentimentPageText5 from "../components/LuxurySentimentPage/SentimentPageText5"
// import SentimentPagePic4 from "../components/LuxurySentimentPage/SentimentPagePic4"
// import SentimentPageText3 from "../components/LuxurySentimentPage/SentimentPageText3"
// import SentimentPagePic3 from "../components/LuxurySentimentPage/SentimentPagePic3"
// import SentimentPageText4 from "../components/LuxurySentimentPage/SentimentPageText4"
// import SentimentPageText6 from "../components/LuxurySentimentPage/SentimentPageText6"
// import SentimentPageText7 from "../components/LuxurySentimentPage/SentimentPageText7"
// import SentimentPageText8 from "../components/LuxurySentimentPage/SentimentPageText8"
// import SentimentPageText9 from "../components/LuxurySentimentPage/SentimentPageText9"
import DataConsultancyPageNewsletter from "../components/DataConsultancyPage/DataConsultancyPageNewsletter"
import { Helmet } from "react-helmet";


function DataConsultancyPage({ data }) {
  return (
    <Layout>
      <Helmet>
        {/* <meta property="og:image" content="https://images.ctfassets.net/x5jqyuhgi4rx/3EMP1aRMHimHfO5MQ7qoZ8/2659df6bfd8cd6ff4b343ec787a02a93/blog-post.png" /> */}
        <meta property="og:title" content="Luxury Data Consultancy" />
        <meta property="og:image" content="https://images.ctfassets.net/x5jqyuhgi4rx/2THFomyWl38CNaDgeNO3K8/9353743d93a5c4502128c57321867562/Asset_21_2x.png" />
        <meta property="og:description" content="How Top Luxury Leaders and Investors Dominate markets" />
        <meta property="og:url" content="https://thefreshkid.com/luxury-data-consultant/" />
      </Helmet>
      <HeroImageDataConsultancy />
      <DataConsultancyPageText1 />
      <DataConsultancyPicture1 />
      <DataConsultancyPageText2 />
      <DataConsultancyPicture2 />
      <DataConsultancyPageText3 />
      <DataConsultancyPicture3 />
      <DataConsultancyPageText4 />
      <DataConsultancyPicture4 />
      <DataConsultancyPageText5 />
      <DataConsultancyPicture5 />
      <DataConsultancyPageText6 />

      {/* <SentimentPagePic1 />
      <SentimentPageText2 />
      <SentimentPagePic2 />
      <SentimentPageText5 />
      <SentimentPagePic4 />
      <SentimentPageText3 />
      <SentimentPagePic3 />
      <SentimentPageText4 />
      <SentimentPageText6 />
      <SentimentPageText7 />
      <SentimentPageText8 />
      <SentimentPageText9 /> */}
      <DataConsultancyPageNewsletter />
    </Layout>
  )
}

export default DataConsultancyPage